import * as React from "react";
import Layout from "../components/layout";
import SimpleSlider from "../components/slider";
import "../main.scss";
import WM from "../images/peacepoint-logo-watermark.svg";
import Book from "../images/peacemaking-book.jpg";
import Tim from "../images/meet-peacepoint-thumb.png";
import { BsFillPlayFill } from "react-icons/bs";

const IndexPage = () => {
  return (
    <Layout>
      <section id="hero">
        <div class="content-wrapper">
          <h1>We are here to help.</h1>
          <p>
            God expects Christians to actively seek genuine peace when conflicts
            and disputes arise. While He has provided everything you need to{" "}
            <a href="/find-peace" class="underline">
              live at peace
            </a>
            , it is not easy.
          </p>
          <a href="/find-peace" class="main-button">
            Find Peace
          </a>
        </div>
        <div class="img-wrapper">
          <img src={WM} alt="Logo" id="watermark" />
        </div>
      </section>
      <section>
        <div class="img-wrapper">
          <img src={Tim} alt="Tim Sellers" class="home-img gold-border" />
        </div>
        <div class="content-wrapper">
          <h2>Meet PeacePoint.</h2>
          <p>
            In this 2 1/2 minute video, PeacePoint President Tim Sellers shares
            who we are, what we do and why we do it.
          </p>
          <a
            href="https://player.vimeo.com/video/56178927?title=0&byline=0&portrait=0&color=622d91"
            target="_blank"
            rel="noreferrer"
            class="main-button"
          >
            <BsFillPlayFill class="play" />
            Watch Our Story
          </a>
        </div>
      </section>
      <section class="book">
        <div class="content-wrapper">
          <h2>PEACEMAKING</h2>
          <h3>A Field Manual.</h3>
          <p>
            Conflict is everywhere. No one can escape it.
            <br />
            <br />
            While conflict may be inevitable, you don't have to be defeated by
            it. PEACEMAKING lays out God's perfect plan for conquering conflict.
          </p>
          <br />
          <a
            href="https://a.co/d/9EjAZjE"
            target="_blank"
            rel="noreferrer"
            class="main-button"
          >
            Buy Book
          </a>
        </div>
        <div class="img-wrapper">
          <img src={Book} alt="Jessica" class="home-img gold-border" />
        </div>
      </section>
      <SimpleSlider />
      <div class="home-nav">
        <a href="/our-services" class="square" id="service">
          <div>Our Services</div>
        </a>
        <a href="/education-and-training" class="square" id="training">
          <div>Education &amp; Training</div>
        </a>
        <a href="/find-peace" class="square" id="peace">
          <div>Find Peace</div>
        </a>
        {/* <a href="/blog" class="square" id="blog"><div>Blog</div></a> */}
      </div>
    </Layout>
  );
};

export default IndexPage;
