import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: false,
      fade: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 10000,
      adaptiveHeight: true,
    };
    return (
      <div>
        <Slider {...settings}>
          <div>
            <blockquote id="endorcements">
              In PEACEMAKING, attorney and Christian conciliator Tim Sellers
              provides insightful, biblical, principles and practical guidance
              to facilitate reconciliation and peace even when all seems lost.
              Sellers teaches and shares out of hard-earned experience as well
              as proven best practices and historic examples that bring
              peace-making concepts to life. Living at peace is at the heart of
              God’s perfect plan for us individually and for those in our
              relational world. This book provides powerful resources to conquer
              even the most difficult conflicts that threaten family, business,
              and church relationships. You will greatly benefit on multiple
              levels from this fresh look at eternally important issues.
              <br />
              <br />
              <cite>
                &mdash; Chip Ingram, best-Selling author and CEO of Living on
                the Edge, an international teaching and discipleship ministry
              </cite>
            </blockquote>
          </div>
          <div>
            <blockquote id="endorcements">
              As counter intuitive as it may seem, peacemaking is indeed a type
              of warfare. Making peace typically forces us to wage war against
              the flesh, the world and the devil, all of which are eager to
              promote their own agendas at the expense of peace. In PEACEMAKING,
              Tim Sellers ably teaches that peacemakers must often be warriors,
              wielding spiritual weapons while simultaneously displaying the
              love, humility and courage that comes from the Holy Spirit.
              <br />
              <br />
              <cite>
                &mdash; Ken Sande – author of The Peacemaker and founder of
                Peacemaker Ministries and RW360
              </cite>
            </blockquote>
          </div>
          <div>
            <blockquote id="endorcements">
              Tim Sellers is like all of us: he likes and wants to be at peace.
              Don’t you? He’s also a realist who knows that this world offers
              numerous obstacles to us finding the peace we so desire. Tim’s had
              years of experience identifying and dealing with the disturbers of
              peace. He knows what they look like, and he knows how to handle
              them. If you or someone you know is asking “how can I conquer
              conflict and find real peace,” PEACEMAKING has the answers you’re
              looking for.
              <br />
              <br />
              <cite>
                &mdash; Walt Wiley, Founder and President of Winning with
                Encouragement
              </cite>
            </blockquote>
          </div>
          <div>
            <blockquote id="endorcements">
              If the idea of engaging in conflict scares or makes you cringe,
              you aren’t alone. If you’ve been looking for a framework to pursue
              peaceful outcomes in the midst of unsolved conflicts, you’ve found
              it. PEACEMAKING is an authentic and thought-provoking playbook for
              resolving conflicts and winning the war for peace. Tim Sellers
              provides sound guidance for conquering conflicts that’s both
              practical and biblically sound. He shares stories and experiences
              that will inspire you to “fight the good fight” to find and hold
              on to genuine peace. He convincingly makes the case that no one
              needs to struggle and suffer with conflicts if they are just
              willing to trust God and to say YES to resolving them His way.
              <br />
              <br />
              <cite>
                &mdash; Manny Ohonme, Founder, President and CEO of Samaritan’s
                Feet International
              </cite>
            </blockquote>
          </div>
        </Slider>
      </div>
    );
  }
}
